<template>
  <div class="user">
    <!-- 面包屑导航 -->
    <el-card>
      <!-- 头 -->
      <el-row :gutter="20">
        <el-col :span="5">
          <el-input placeholder="请输入内容" v-model="getInfo.info">
            <el-button
              slot="append"
              icon="el-icon-search"
            ></el-button>
          </el-input>
        </el-col>
        <el-col :span="8"> </el-col>
          <el-tooltip class="item" effect="dark"  content="增加兑换卷" placement="top-start">
            <el-button  @click="addExp" type="primary">增加兑换卷</el-button>
        </el-tooltip>
      </el-row>
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        v-if="tableData"
      >
        <el-table-column align="center"   prop="id" label="ID"></el-table-column>
        <el-table-column align="center" prop="name" label="兑换卷标题"></el-table-column>
        <el-table-column align="center"   prop="description" label="描述"></el-table-column>
        <el-table-column align="center"   prop="days" label="有效天数"></el-table-column>
        <el-table-column align="center"  label="类型">
          <template slot-scope="scope">
            <div>
              <div v-if="scope.row.type==1">兑换卷</div>
              <div v-else>商品兑换卷</div>
                    <!-- <el-select v-model="valueType" @change="changeValue" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option> 
            </el-select>-->
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" width="100px"  prop="count" label="数量"></el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
             <el-tooltip class="item" effect="dark"  content="修改兑换卷" placement="top-start">
            <el-button icon="el-icon-edit" type="primary" @click="updataExp(scope.row)" ></el-button>
          </el-tooltip>
             <el-tooltip class="item" effect="dark"  content="删除兑换卷" placement="top-start">
            <el-button icon="el-icon-delete" type="danger" @click="delExp(scope.row.id)" ></el-button>
          </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @current-change="pageChange"
        @size-change="sizeChange"
        :page-sizes="[7, 15, 20]"
        layout="total, sizes, prev, pager, next"
        :page-size="this.getInfo.page_num"
        background
        :total="exp.num"
      >
      </el-pagination>
    </el-card>
    <!-- 删除兑换卷模态框 -->
    <el-dialog
  title="提示"
  :visible.sync="dialogVisible"
  width="30%"
  :before-close="handleClose">
  <span>是否确定删除该兑换卷?</span>
  <span slot="footer" class="dialog-footer">
    <el-button @click="refuse">取 消</el-button>
    <el-button type="primary" @click="consent">确 定</el-button>
  </span>
</el-dialog>
<!-- 增加兑换卷模态框 -->
<el-dialog
      :visible.sync="AddDialogFormVisible"
      :append-to-body="true"
      :before-close="AddhandleClose"
    >
      <div slot="title" class="dialog-footer">
        <div class="title" v-show="openState">添加兑换券</div>
        <div class="title" v-show="!openState">修改兑换券</div>
      </div>
      <div class="diaBox">
                 <el-form  :model="addExpForm" label-width="100px" :rules="rules" ref="ruleForm">
       <el-form-item label="id"  v-if="!openState">
         <el-input disabled v-model="addExpForm.id" placeholder="" style="width:200px;"></el-input>
        </el-form-item>
        <el-form-item label="兑换卷标题" prop="Name">
         <el-input v-model="addExpForm.name" placeholder="请输入兑换卷标题(必填)" style="width:200px;"></el-input>
        </el-form-item>
        <el-form-item label="描述" >
          <el-input v-model="addExpForm.description"  placeholder="请输入兑换卷标题(选填)" style="width:200px;"></el-input>
        </el-form-item>
         <el-form-item type="number" label="有效天数" prop="Days">
          <el-input v-model="addExpForm.days"  placeholder="请输入有效天数(必填)" style="width:200px;"></el-input>
        </el-form-item>
        <el-form-item label="类型 " prop="Type">
          <el-select v-model="addExpForm.type" placeholder="请选择类型(必填)" style="width:200px;" >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option> 
            </el-select>
        </el-form-item>
         <el-form-item label="数量"  prop="Count">
          <el-input type="number"  placeholder="请输入数量(必填)" v-model="addExpForm.count" style="width:200px;"></el-input>
        </el-form-item>

      </el-form>
      </div>
      
      <div slot="footer" class="dialog-footer">
        <el-button @click="addExpCancel">取 消</el-button>
        <el-button type="primary" @click="setAdd('ruleForm')" v-show="openState"
          >添 加</el-button
        >
        <el-button type="primary" @click="setEdit" v-show="!openState"
          >修 改</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import {PostSetExchange,PostDelExchange,PostUpdataExchange,PostAddExchange} from "@/api/Expchange/setExp.js";

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
     var validateName = (rule, value, callback) => {
        if (this.addExpForm.name === '') {
          callback(new Error('标题不能为空！'));
        } else {
          callback();
        }
      };
       var validateDays = (rule, value, callback) => {
        if (this.addExpForm.days === '') {
          callback(new Error('有效天数不能为空！'));
        } else {
          callback();
        }
      };
      var validateType = (rule, value, callback) => {
        if (this.addExpForm.type === '') {
          callback(new Error('请选择类型！'));
        } else {
          callback();
        }
      };
       var validateCount = (rule, value, callback) => {
        if (this.addExpForm.count === '') {
          callback(new Error('数量不能为空！'));
        } else {
           console.log(11111)
          callback();
        }
      };
    //这里存放数据
    return {
      //增加兑换卷模态框form
       addExpForm:{
        name:'',
        description:'',
        days:'',
        type:'',
        count:'',
        id:''
      },
        rules: {
          Name: [
            { validator: validateName, trigger: 'blur' }
          ],
           Days: [
            { validator: validateDays, trigger: 'blur' }
          ],
           Type: [
            {  validator: validateType, trigger: 'change'  }
          ],
           Count: [
            { validator: validateCount, trigger: 'blur' }
          ],
        },
      //删除兑换卷模态框
      dialogVisible:false,
      //增加兑换卷模态框
      AddDialogFormVisible:false,
      //家庭注销模态框
      dialogVisible:false,
      openState:true,
      dialogFalimyID:'',
      //总条数
      exp: {
        count: Number,
        num: 0,
      },
      valueType: '',
      options: [
        {
          value: '1',
          label: '兑换卷'
        },
         {
          value: '2',
          label: '商品兑换卷'
        },
        ],
      //当前页码
      getInfo: {
        //搜索框
        info: "",
        page_code: 1,
        page_num: 15,
      },
      //表格数据
      tableData: [],
      //家庭Id
      ExpID:"",//兑换卷ID
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    // changeValue(e){
    //     console.log(e);
    //     console.log(111)
    // },
    setAdd(formName){
      var that=this;
       this.$refs[formName].validate((valid) => {
          if (valid) {
            this.$confirm('确认增加？')
          .then(_ => {
                console.log(this.addExpForm);
                that.PostAddExchange(this.addExpForm)
          }).catch((err)=>{
            console.log(err)
          })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      
       console.log("确认增加")
    },
    setEdit(){
        console.log("确认修改")
         this.$confirm('确认修改？')
          .then(_ => {
                console.log("确定")
                var data={
                  id:this.ExpID
                }
                if(this.addExpForm.type=="兑换卷"){
                       this.addExpForm.type=1
                }else if(this.addExpForm.type=="商品兑换卷"){
                       this.addExpForm.type=0
                }
                console.log(this.addExpForm)
                this.PostUpdataExchange(this.addExpForm);
                this.AddDialogFormVisible=false;
          }).catch(()=>{
              console.log("取消")
              this.dialogVisible=false;
          })
        
    },
    //增加兑换卷
    addExp(){
       this.AddDialogFormVisible=true;
       this.openState=true;
       console.log("增加兑换卷");
    },
    //修改兑换卷
    updataExp(item){
      if(item.type==0){
         this.addExpForm={
           id:item.id,
           name:item.name,
        description:item.description,
        days:item.days,
        type:"商品兑换卷",
        count:item.count,
         }
      }else if(item.type==1){
           this.addExpForm={
           id:item.id,
           name:item.name,
        description:item.description,
        days:item.days,
        type:"兑换卷",
        count:item.count,
         }
      }
         this.AddDialogFormVisible=true;
         this.openState=false;
         this.ExpID=item.id;
         
    },
    //删除兑换卷
    delExp(id){
      this.ExpID=id;
         console.log(id);
         this.dialogVisible=true;

    },
    //修改每页数量
    sizeChange(value) {
      this.getInfo.page_num = value;
      var data={
      page:this.getInfo.page_code,
      list_num:this.getInfo.page_num
      
    }
    console.log(data);
    this.tableData=[]
    this.PostSetExchange(data);
    },
    //分页切换
    pageChange(value) {
      this.getInfo.page_code = value;
      var data={
      page:this.getInfo.page_code,
      list_num:this.getInfo.page_num
    }
    console.log(data);
    this.tableData=[]
   this.PostSetExchange(data);
    },
    //取消删除兑换卷
    refuse(){
          this.dialogVisible=false;
          console.log("取消删除兑换卷")
          
    },
    //同意删除兑换卷
    consent(){
         this.$confirm('确认删除？')
          .then(_ => {
                console.log("确定")
                var data={
                  id:this.ExpID
                }
                this.PostDelExchange(data);
                this.dialogVisible=false;
          }).catch(()=>{
              console.log("取消")
              this.dialogVisible=false;
          })
    },
    //取消增加兑换卷模态框
    addExpCancel(){
          this.AddDialogFormVisible=false;
              this.addExpForm={
        name:'',
        description:'',
        days:'',
        type:'',
        count:''
    }
    },
    handleClose(done) {
        this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {});
      },
      AddhandleClose(done){
          this.$confirm('确认关闭？')
          .then(_ => {
            done();
              this.addExpForm={
        name:'',
        description:'',
        days:'',
        type:'',
        count:''
    }
          })
          .catch(_ => {});
      },
    /**-----------------网络请求生命周期函数--------------------- */
    //修改兑换卷
    PostUpdataExchange(data){
      var that=this;
        let res=PostUpdataExchange(data);
        res.then((res)=>{
          console.log(res);
          if(res.data.code==1){
              this.$message({
          message: res.data.message,
          type: 'success'
        });
         var data={
      page:that.getInfo.page_code,
      list_num:that.getInfo.page_num
    }
         this.addExpForm={
        name:'',
        description:'',
        days:'',
        type:'',
        count:''
    }
    this.tableData=[]
    that.PostSetExchange(data);
          }else{
           this.$message.error(res.data.message);
        }
        }).catch((err)=>{
             console.log(err)
        })
    },
    //增加兑换卷
     PostAddExchange(data){
      console.log(data)
       let res= PostAddExchange(data);
       res.then((res)=>{
              if(res.data.code==1){
              this.$message({
          message: res.message,
          type: 'success'
        });
         var data={
      page:this.getInfo.page_code,
      list_num:this.getInfo.page_num
    }
    this.tableData=[]
    this.PostSetExchange(data);
    this.AddDialogFormVisible=false;
    this.addExpForm={
        name:'',
        description:'',
        days:'',
        type:'',
        count:''
    }
        }else{
           this.$message.error(res.data.message);
        }
       }).catch((err)=>{
             console.log(err);
       })
    },
    //删除兑换卷
    PostDelExchange(data){
      var that=this;
        let res=PostDelExchange(data);
        res.then((res)=>{
          console.log(res);
          if(res.data.code==1){
              this.$message({
          message: res.data.message,
          type: 'success'
        });
         var data={
      page:that.getInfo.page_code,
      list_num:that.getInfo.page_num
    }
    this.tableData=[]
    that.PostSetExchange(data);
          }
          
            console.log(res.data)
        }).catch((err)=>{
             console.log(err)
        })
    },
    //兑换卷设置
    async PostSetExchange(data){
        let res=await PostSetExchange(data);
        if(res.data.code==1){
          this.tableData=this.tableData.concat(res.data.data.data);
        }else{
           this.$message.error(res.data.message);
        }
        console.log(res.data);
    }

  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    var data={
      page:this.getInfo.page_code,
      list_num:this.getInfo.page_num
    }
    this.PostSetExchange(data);

  },
  beforeMount() {}, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
      
  },
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
};
</script>

<style scoped>
.page {
  margin-top: 20px;
}
.title {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}
.diaBox{
  display: flex;
  justify-content: center;
}
.set-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.set-page-left {
  color: #0000006d;
  font-size: 14px;
  font-weight: 400;
}
</style>