import { post } from '@/utils/request'

export function PostSetExchange(data) {
    return post('admin/exchange/setting', data)
}
export function PostDelExchange(data) {
    return post('admin/exchange/setting/del', data)
}
export function PostAddExchange(data) {
    return post('admin/exchange/setting/add', data)
}
export function PostUpdataExchange(data) {
    return post('admin/exchange/setting/edit', data)
}